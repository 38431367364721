html {
	font-size: 10px;
	height: 100%;
	line-height: 1.4;
	@include css-lock(6, 10, $tablet, $desktopLg);

	@include md {
		@include css-lock(4, 6, $mob, $tablet); } }

body {
	background: #000;
	color: #D8D1D1;
	font-size: 3.6rem;
	height: 100vh;
	// height: calc(var(--vh, 1) * 100)
	line-height: 1.2;
	letter-spacing: 0.025em;
	overflow: hidden;
	overscroll-behavior: none;
	width: 100vw;
	// width: calc(var(--vw, 1) * 100)
	@include reg; }

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400; }

section {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	min-width: 100%; }

[data-barba="container"],
[data-barba="wrapper"] {
	height: 100%;
	width: 100%;

	&.is-fixed {
		height: 100vh;
		height: calc(var(--vh, 1) * 100);
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		width: calc(var(--vw, 1) * 100); } }

.out {
	height: 100%;

	&__back {
		height: 100%;
		left: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		width: 100%; }

	&__in {
		height: 100%;
		opacity: 0;
		position: relative;
		visibility: hidden; } }

body.is-mobile {
	height: 100vh;
	height: calc(var(--vh, 1) * 100);
	overflow: hidden;
	width: 100vw;
	width: calc(var(--vw, 1) * 100); }

body.scroll {
	overflow: auto;
	height: 100%; }
