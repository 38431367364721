.progress {
  font-size: 14px;
  @include mont-light;

  &__in {
    align-items: center;
    display: flex;
    justify-content: space-between; }

  &__line,
  &__num,
  &__values {
    display: block; }

  &__line {
    background-color: #ffffff;
    // border: 1px solid #ffffff
    height: 1px;
    margin: 0 5px;
    opacity: 0.2;
    width: 53px; }

  &__num {
    overflow: hidden;
    text-align: right;
    white-space: nowrap;
    width: 1.02em;

    &:last-of-type {
      text-align: left; } }

  &__values {
    transition: transform 1s cubic-bezier(1,.15,.25,1);
    will-change: transform;

    & > span {
      display: inline-block;
      width: 1em; } } }
