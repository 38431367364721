.loader {
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  overflow: hidden;
  padding: $y;
  position: fixed;
  text-align: center;
  width: 100vw;
  width: calc(var(--vw, 1) * 100);

  &__in {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    justify-content: center;
    width: 100%; }

  &__texts {
    position: relative;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: inherit;

    p {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      visibility: hidden;
      width: 100%; } } }
