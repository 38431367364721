.other-stories-deep {
    position: relative;
    text-align: left;
    user-select: none;
    overflow: auto;

    a {
        text-decoration: none;
        color: #D8D1D1; }

    .wrapper {
        margin: 70px auto;
        max-width: 1200px;
        padding: 0 16px;
        // min-height: 100%
        height: auto;
        display: flex;
        flex-direction: column;

        @include r(900) {
            margin: 45px auto;
            height: calc(100% - 90px); }

        .back {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding-left: 34px;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 161.5%;
            color: #D8D1D1;
            width: 100%;
            @include mont-req;

            @include r(1199) {
                width: 83%; }
            @include r(900) {
                padding-left: 0; }

            &--arrow {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                fill: transparent;
                stroke: #D8D1D1;
                width: 14px;
                height: 100%;

                @include r(900) {
                    top: 29%;
                    left: -7rem;
                    width: 13px; }
                img {
                    width: 100%;
                    height: 100%; } } } }

    &__video-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 55.25%;
        margin: 24px 0;

        @include r(1199) {
            width: 83%;
            margin: 24px auto; }


        .navigation {
            position: absolute;
            width: 100%;
            height: 100%;

            &--arrow {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);

                @include r(1199); }


            .left {
                left: -7rem;
                @include r(1199); }


            .right {
                right: -7rem;
                transform: translate(0,-65%) rotate(180deg);
                @include r(1199); } }



        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;

            video {} } }


    &__description {

        &--headline {
            font-size: 24px;
            line-height: 161.5%;
            color: #D8D1D1;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(250, 249, 249, 0.7);
            @include mont-req;

            @include r(900) {
                font-size: 18px;
                line-height: 161.5%; } }

        &--text {
            margin: 24px 0;
            font-size: 22px;
            line-height: 34px;
            color: #C4C4C4;
            @include mont-req;

            @include r(900) {
                font-size: 14px;
                line-height: 23px; } } }


    .pagination {
        width: 100%;
        height: 100%;
        @include r(900) {
            display: flex;
            justify-content: flex-end;
            margin-right: -7rem; }


        &-content {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 10px;
            font-size: 14px;
            line-height: 17px;
            color: #fff;
            @include mont-light;

            &__line {
                width: 53px;
                height: 1px;
                margin: 0 5px;
                background: #fff; } } }

    .footer {
        margin-top: 170px;
        font-size: 12px;
        line-height: 15px;
        color: rgba(255, 255, 255, 0.5);
        @include mont-req;
        @include r(900) {
            margin-top: auto; }
        a {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
            color: rgba(255, 255, 255, 0.5); } } }


