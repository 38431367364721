.timeline {
  // cursor: grab
  overflow: hidden;
  position: relative;
  text-align: left;
  user-select: none;

  &:active {
    cursor: grabbing; }

  &__in {
    display: flex; }

  &__slide {
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    display: inline-flex;
    height: 100vh;
    height: calc(var(--vh, 1) * 100);
    overflow: hidden;
    // padding-left: 100px
    position: relative;
    min-width: 100vw;
    min-width: calc(var(--vw, 1) * 100);
    will-change: transform; }

  [data-slide="default"],
  [data-slide="video"] {
    max-width: 100vw;
    max-width: calc(var(--vw, 1) * 100); }

  [data-slide="gallery"] {
    opacity: 0; }

  &__progress {
    left: $x;
    position: fixed;
    top: $y; }

  &__arrow {
    bottom: $y;
    cursor: pointer;
    font-size: 4.6rem;
    left: 50%;
    opacity: 0.6;
    position: fixed;
    transition: opacity 0.3s;

    &:nth-child(1) {
      transform: translateX(-300%); }

    &:nth-child(2) {
      transform: translateX(200%); }

    &:hover {
      opacity: 0.8; }

    svg {
      display: block;
      fill: none;
      stroke: #ffffff; } } }

