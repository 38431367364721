.trying {
  $this: &;
  height: 100%;
  position: relative;
  width: 100%;

  &__item {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:last-child {
      #{$this}__block {
        transform: translateY(-100%);

        #{$this}__headline {
          transform: none;
          width: calc(100vw * 0.25); }

        [lang="en"] & {
          #{$this}__headline {
            width: calc(100vw *.35); } }

        #{$this}__text {
          padding-top: 3rem; } } } }

  [data-story="first"] & {
    #{$this}__item {
      &:last-child {
        #{$this}__block {
          #{$this}__headline {
            transform: translateY(50%); } } } } }

  [lang="en"] & {
    #{$this}__item {
      &:nth-child(1) {
        #{$this}__block {
          #{$this}__headline {
            left: calc(100vw * 0.071); } } }

      &:nth-child(2) {
        #{$this}__block {
          #{$this}__headline {
            left: calc(100vw * 0.068); } } }

      &:nth-child(3) {
        #{$this}__block {
          #{$this}__headline {
            left: calc(100vw * 0.074); } } } } }

  [data-story="second"] & {
    #{$this}__block {
      #{$this}__headline {
        transform: translateY(-50%); } }

    #{$this}__item {
      &:last-child {
        #{$this}__block {
          #{$this}__headline {
            transform: translateY(0); } } } } }

  &__block {
    left: 50%;
    margin-left: -25vw;
    opacity: 0;
    position: absolute;
    // top: calc(100vh * 0.43)
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    width: calc(100vw * 0.50);

    &_mod {
      #{$this}__headline {
        padding-right: 10rem;
        width: auto; } } }

  &__headline {
    color: #D8D1D1;
    display: inline-block;
    font-size: 4.8rem;
    line-height: 1.2;
    letter-spacing: 0.015em;
    left: calc(100vw * 0.073);
    position: relative;
    white-space: nowrap;
    width: calc(100vw * 0.4);
    @include light-italic;

    svg {
      width: 100%; } }

  &__text {
    color: #DCDBDB;
    font-size: 2.2rem;
    line-height: 1.5em;
    left: 30.6%;
    padding-top: 4.5rem;
    position: absolute;
    top: 100%;
    width: 100%;
    @include mont-light;

    @include rh($mob) {
      font-size: 1.8rem;
      padding-top: 2.5rem; }

    p {
      white-space: nowrap; } }

  &__dots {
    span {
      display: block;
      position: absolute;

      height: 5px;
      // background-color: red
      border-radius: 50%;
      width: 5px;

      $points: (("top": "50%",
      "right": "7rem")("top": "calc(50% - 5rem)",
      "right": "0")("top": "50%",
      "right": "3rem")("top": "10rem",
      "right": "2rem")("top": "-2rem",
      "right": "-3rem")("top": "3rem",
      "right": "0")("top": "10rem",
      "right": "-1rem")("top": "7rem",
      "right": "-3rem")("top": "10rem",
      "right": "-5rem")("top": "-2rem",
      "right": "-12rem")("top": "-7rem",
      "right": "-12rem")("top": "-1rem",
      "right": "-8rem")("top": "11rem",
      "right": "-9rem")("top": "7rem",
      "right": "-12rem")("top": "8rem",
      "right": "-14rem")("top": "5rem",
      "right": "-16rem")("top": "0",
      "right": "-16rem")("top": "6rem",
      "right": "-14rem")("top": "10rem",
      "right": "-16rem")("top": "5rem",
      "right": "-20rem")("top": "7rem",
      "right": "-38rem"));

      @each $item in $points {
        @each $type, $value in $item {
          $i: index($points, $item);
          &:nth-child(#{$i}) {
            #{$type}: #{$value}; } } } } } }
