.error-layout {
  background-image: url('./../img/back.jpg');
  background-size: cover;
  background-position: center;
  display: none;
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  font-size: 18px;
  left: 0;
  letter-spacing: 0.025em;
  line-height: 1.2;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  width: calc(var(--vw, 1) * 100);
  @include reg;
  @include css-lock(16, 18, $mob, $desktop);

  &__in {
    display: none; }

  &.is-animating {
    opacity: 1;
    visibility: visible;

    p {
      opacity: 1;
      visibility: visible;
      transform: translateY(0) scale(1);
      transition: opacity ease-in 0.8s, visibility ease-in 0.8s, transform ease-in 0.8s;
      transition-delay: 0.3s;

      &:last-of-type {
        transition-delay: 1.1s; } } }

  p {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-25%) scale(1.1);

    &:not(:last-of-type) {
      margin-bottom: 1.2em; } }

  a {
    color: #ffffff; }

  .is-mobile &,
  .is-ie & {
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center; }

  .is-mobile & {
    &__in {
      &.is-mob {
        display: block; } } }

  .is-ie & {
    &__in {
      &.is-ie {
        display: block; } } } }
