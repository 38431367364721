.museum {
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  width: 100%;

  &__in {
    height: 100%;
    width: 100%; }

  &__sound {
    background-color: transparent;
    bottom: $y;
    cursor: pointer;
    height: 28px;
    opacity: 0;
    position: fixed;
    right: $x;
    visibility: hidden;
    width: 28px;
    z-index: 1; }

  &__wave {
    height: inherit;
    left: 0;
    position: absolute;
    top: 0;
    width: inherit;

    body:not(.is-safari) & {
      &:hover {
        canvas {
          filter: url('#glitch');
          -webkit-filter: url('#glitch'); } } } } }
