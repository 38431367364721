.gallery {
  $this: &;
  padding: 0 67vw 0 20vw;
  will-change: transform;

  &__in {
    position: relative;
    top: -3rem; }

  &__row {
    align-items: center;
    display: flex;

    &_back {
      display: flex;
      height: 100%;
      left: -20rem;
      opacity: 0;
      position: absolute;
      top: 0;
      justify-content: space-between;
      width: 50%;

      img {
        max-height: 45.7rem;
        max-width: 35rem;

        &:nth-of-type(2n) {
          transform: translateY(50%); }

        &:nth-of-type(2n + 1) {
          transform: translateY(-50%); } } } }

  &__item {
    cursor: default;
    font-size: 18px;
    position: relative;
    @include mont-req;

    @include rh($mob) {
      font-size: 1.6rem; }

    &:first-of-type {
      margin-left: 0; }

    img {
      display: block;
      height: auto;
      max-height: 55vh;

      @include rh($mob) {
        max-height: 50vmin; } }

    & > img {
      margin-left: auto; }

    &_padding {
      &-lg {
        margin-left: 265px;
        margin-right: 130px;

        #{$this}__text {
          bottom: 0;
          padding-bottom: 0; } } }

    &_image {
      & + #{$this}__item_date {
        margin-left: 360px; } }

    &_audio {
      margin-left: 340px;

      & + #{$this}__item_image {
        margin-left: 340px; } }

    &_date {
      margin-left: 268px;

      & + #{$this}__item_audio {
        margin-left: 268px; }

      & + #{$this}__item_object {
        margin-left: 200px; }

      & + #{$this}__item_image {
        margin-left: 235px; } }

    &_object {
      margin-left: 340px;

      & + #{$this}__item_date {
        margin-left: 356px; }

      #{$this} {
        &__text {
          left: 120px;
          margin-top: 33px;
          width: 380px;
          top: calc(100% + 20px);

          &:before {
            bottom: 0.5em;
            border: 1px solid rgba(#E1DADA, 0.4);
            content: '';
            height: 1px;
            left: -90px;
            position: absolute;
            transform: rotate(45deg);
            transform-origin: top left;
            width: 28%; } } } } }


  &__headline {
    padding-top: 1rem;
    padding-left: 4.5rem;
    position: relative;

    &:before {
      border: 1px solid rgba(#E1DADA, 0.4);
      content: '';
      left: 0;
      position: absolute;
      top: 2.2rem;
      transform: rotate(-42deg);
      transform-origin: top left;
      width: 7rem; }

    &_pos {
      &_top {
        margin-bottom: 4rem;

        @include rh($mob) {
          margin-bottom: 3rem; } }

      &_bottom {
        margin-top: 4rem;
        padding-top: 0;

        @include rh($mob) {
          margin-top: 3rem; }

        &:before {
          bottom: 2.5rem;
          top: auto;
          transform: rotate(42deg); }

        & + #{$this}__text {
          &_right {
            max-height: 60vh; } } } } }

  &__title {
    font-size: 1.4rem;
    line-height: 1.2;
    margin-bottom: 5px;
    @include apple;

    img {
      display: block;
      max-height: 4.4rem;
      max-width: 9.8rem;
      opacity: 1;

      @include rh($mob) {
        max-height: 4rem;
        min-width: 6rem; } } }

  &__descr {
    white-space: nowrap; }

  &__text {
    bottom: 0;
    color: #C4C4C4;
    line-height: 1.4;
    letter-spacing: 0.02em;
    left: calc(100% + 20px);
    max-width: 480px;
    padding-bottom: 30px;
    position: absolute;
    width: calc(104% + 20px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.8s, visibility 0.8s;
    white-space: nowrap;

    &_lg {
      max-width: 470px;
      width: 200%; }

    &_sm {
      max-width: 387px; }

    &_md {
      max-width: 470px; }

    &_bottom {
      left: 50%;
      padding-top: 10px;
      position: relative;
      width: calc(70% - 10px); }

    &_top {
      bottom: auto;
      top: 0; }

    &_right {
      display: flex;
      flex-direction: column;
      top: 0;
      justify-content: flex-end; }

    p {
      white-space: nowrap;

      &:not(:last-of-type) {
        margin-bottom: 0; } } }

  &__btn {
    background-color: transparent;
    display: block;
    height: 28px;
    position: relative;
    transform-origin: center;
    width: 28px;

    &,
    &:before {
      transition: transform 0.3s; }

    &:hover {
      transform: scale(2);

      &:before {
        animation: none;
        transform: translate(-50%, -50%) scale(0.8); } }

    &:before {
      animation: pulse 1.5s ease-out alternate running infinite;
      border: 1px solid rgba(#DAD2D1, 0.2);
      border-radius: 50%;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) scale(2);
      width: 101%; }

    svg {
      fill: #DCD2D1;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: visibility 0.3s, opacity 0.3s;
      visibility: visible;
      width: 100%;

      &:nth-of-type(2) {
        opacity: 0;
        visibility: hidden; } }

    &.is-playing {
      svg {
        &:nth-of-type(1) {
          opacity: 0;
          visibility: hidden; }

        &:nth-of-type(2) {
          opacity: 1;
          visibility: visible; } } } }

  &__date {
    padding-top: 5px;
    @include light-italic;

    &:before {
      background-color: #C4C4C4;
      content: '';
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%, -50%); }

    &_year {
      &:before {
        border-radius: 50%;
        height: 5px;
        width: 5px; } }

    &_month {
      &:before {
        height: 1px;
        transform: translateX(-50%) rotate(-45deg);
        width: 10px; } } }

  &__photo {
    cursor: pointer;

    &:hover {
      & ~ #{$this}__text {
        opacity: 1;
        visibility: visible; } } }

  &__dot {
    display: block;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

  &__parallax-item {
    max-width: 350px;

    img {
      width: 100%; } }

  [data-story="second"] & {
    &__item {
      &_date {
        & + #{$this}__item_image {
          margin-left: 267px; } }

      &_image {
        & + #{$this}__item_date {
          margin-left: 235px; } } } } }


@keyframes pulse {
  0%, 50% {
    transform: translate(-50%, -50%) scale(1); }
  100% {
    transform: translate(-50%, -50%) scale(2); } }
