.history {
  display: flex;
  position: relative;
  width: 100%;

  &__in {
    height: 100vh;
    padding: 300px 0 500px;
    padding-right: 50px;
    position: relative;
    width: 100%; }

  &__text {
    color: #D8D1D1;
    font-size: 3.6rem;
    line-height: 1.14em;
    letter-spacing: -0.005em;
    padding-bottom: 50vh;

    @include rh($mob) {
      font-size: 3rem; } }

  &__item {
    position: relative;
    left: 50%;
    width: 50%; }

  &__title {
    font-size: 3rem;
    font-weight: normal;
    left: -6.8rem;
    letter-spacing: 0.015em;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    text-align: right;
    width: calc(100% - 6.8rem);
    @include mont-req;

    @include rh($mob) {
      font-size: 2.8rem; } }

  &__dot {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;

    &:nth-of-type(1) {
      top: 50vh;
      top: calc(var(--vh, 1) * 50); }

    &:nth-of-type(2) {
      top: 45vh;
      top: calc(var(--vh, 1) * 45);
      left: 10vw;
      left: calc(var(--vw) * 10); }

    &:nth-of-type(3) {
      top: 67vh;
      top: calc(var(--vh, 1) * 67);
      left: 25vw;
      left: calc(var(--vw) * 25); }

    &:nth-of-type(4) {
      top: 46vh;
      top: calc(var(--vh, 1) * 46);
      left: 50vw;
      left: calc(var(--vw) * 50); }

    &:nth-of-type(5) {
      left: 130vw;
      left: calc(var(--vw, 1) * 130);
      top: 50vh;
      top: calc(var(--vh, 1) * 50); } }

  .ps__rail-x,
  .ps__rail-y {
    opacity: 0;
    visibility: hidden; } }
