.breakpoint {
  color: #D8D1D1;
  height: 50%;
  font-size: 2.4rem;
  line-height: 1.2em;
  letter-spacing: 0.015em;
  text-align: center;

  &__in {
    padding-bottom: 2.4rem;
    position: relative;
    top: 50%;
    transform: translateY(-100%); }

  p {
    &:not(:last-of-type) {
      @include light; }

    &:nth-child(2) {
      bottom: 0;
      font-size: 1.8rem;
      left: 0;
      line-height: 1.2em;
      letter-spacing: 0;
      position: absolute;
      transform: translateY(300%);
      width: 100%;
      @include mont-req; } }

  &__point {
    display: inline-block;
    left: 0;
    position: absolute;
    top: 0;

    &:nth-child(1) {
      left: 0;
      top: 50%; }

    &:nth-child(2) {
      left: 50vw;
      top: 50%; }

    &:nth-child(3) {
      left: 80vw;
      top: 60%; }

    &:nth-child(4) {
      left: 99.9vw;
      top: 50%; } } }
