.icon-360-map {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-360-section {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-left {
  width: 0.52em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 0.52em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-pause {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-twitter {
  width: 1.25em;
  height: 1em;
  fill: initial;
}
.icon-video-arrow {
  width: 0.38em;
  height: 1em;
  fill: initial;
}
