.history {

    .wrapper {
        margin: 70px 127px;
        width: fit-content;
        @include r(900) {
            margin: 45px 24px; }

        .back {
            position: relative;
            padding-bottom: 38px;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(250, 249, 249, 0.7);
            font-size: 36px;
            line-height: 161.5%;
            color: #D8D1D1;
            @include mont-bold;
            @include r(900) {
                font-size: 24px;
                line-height: normal;
                padding-bottom: 24px; }

            &--arrow {
                position: absolute;
                top: 30%;
                left: -43px;
                transform: translate(0, -50%);
                fill: transparent;
                stroke: #D8D1D1;
                stroke-width: 10px;
                width: 16px;

                @include r(900) {
                    top: 32%;
                    left: -18px;
                    width: 14px; }
                img {
                    width: 100%;
                    height: 100%; } } }




        .content {
            padding-top: 50px;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 68px;
            @include r(900) {
                grid-template-columns: repeat(1,1fr);
                grid-gap: 34px;
                padding-top: 33px; }

            &--item {
                grid-column: span 1;
                padding: 33px 31px;
                background: rgba(196, 196, 196, 0.2);
                border-radius: 3px;
                cursor: pointer;
                @include r(900) {
                    padding: 16px; }

                &__headline {
                    font-size: 24px;
                    line-height: 161.5%;
                    color: #D8D1D1;
                    @include mont-bold;
                    @include r(900) {
                        font-size: 18px; } }


                &__image-container {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 55.25%;
                    margin: 17px 0;

                    .image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%; } } }

                &__description {
                    font-size: 22px;
                    line-height: 34px;
                    color: #C4C4C4;
                    @include mont-req;
                    @include r(900) {
                        font-size: 14px;
                        line-height: normal; } } } } } }



