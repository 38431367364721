.other-stories {
    position: relative;
    text-align: left;
    user-select: none;
    overflow: auto;

    a {
        text-decoration: none;
        color: #D8D1D1; }

    .wrapper {
        margin: 70px auto;
        max-width: 1200px;
        padding: 0 16px;
        height: auto;

        @include r(900) {
            margin: 45px auto; }

        .back {
            position: relative;
            padding-bottom: 38px;
            border-bottom: 1px solid rgba(250, 249, 249, 0.7);
            font-size: 36px;
            line-height: 161.5%;
            color: #D8D1D1;
            @include mont-req;
            @include r(1199) {
                padding-left: 34px; }

            @include r(900) {
                font-size: 18px;
                line-height: 161.5%;
                padding-bottom: 24px;
                padding-left: 20px; }


            &--arrow {
                position: absolute;
                top: 30%;
                left: -43px;
                transform: translate(0, -50%);
                fill: transparent;
                stroke: #D8D1D1;
                width: 23px;
                height: 100%;

                @include r(1199) {
                    left: 0; }

                @include r(900) {
                    top: 29%;
                    left: 0;
                    width: 13px; }
                img {
                    width: 100%;
                    height: 100%; } }

            &.desktop {
                display: block;

                @include r(900) {
                    display: none; } }
            &.mobile {
                display: none;

                @include r(900) {
                    display: block; } } }




        .content {
            padding-top: 50px;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            grid-gap: 50px;

            @include r(900) {
                grid-template-columns: repeat(1,1fr);
                grid-gap: 34px;
                padding-top: 33px; }


            &--item {
                display: flex;
                flex-direction: column;
                grid-column: span 1;
                padding: 27px;
                border: 1px solid rgba(250, 249, 249, 0.7);
                border-radius: 3px;
                cursor: pointer;
                justify-content: space-between;

                @include r(900) {
                    padding: 16px; }

                &__headline {
                    font-size: 24px;
                    line-height: 161.5%;
                    color: #D8D1D1;
                    @include mont-req;
                    @include r(900) {
                        font-size: 18px; } }


                &__image-container {
                    position: relative;
                    width: 100%;
                    height: 0;
                    padding-bottom: 55.25%;
                    margin: 17px 0;

                    .image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%; } } }

                &__description {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    margin-bottom: 14px;
                    min-height: 3.8em;
                    max-height: 3.8em;
                    font-size: 18px;
                    line-height: 23px;
                    color: #C4C4C4;
                    @include mont-req;
                    @include r(900) {
                        font-size: 12px;
                        line-height: normal; } }

                &__text {
                    margin-top: auto;
                    font-size: 14px;
                    line-height: 34px;
                    text-transform: uppercase;
                    color: #C4C4C4;
                    @include mont-req;
                    @include r(900) {
                        font-size: 12px; } } } }

        .footer {
            margin-top: 170px;
            font-size: 12px;
            line-height: 22px;
            color: rgba(255, 255, 255, 0.5);
            @include mont-req;
            @include r(900) {
                margin-top: 70px; }

            a {
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                color: rgba(255, 255, 255, 0.5); } } } }


