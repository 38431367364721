.just-say {
  height: 100%;
  width: 100%;

  [lang="en"] & {
    &__logo {
      width: 57rem; } }

  &__in {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: $x;
    position: relative;
    text-align: center;
    justify-content: space-between; }

  &__logo {
    width: 37rem;

    @include rh($mob) {
      width: 28rem; }

    svg {
      display: block; }

    img {
      display: block;
      width: 100%; } }

  &__block {
    font-size: 2rem;
    line-height: 1.2;
    letter-spacing: 0.1em;
    position: fixed;
    top: 50%;
    transform: translateY(-100%);
    @include mont-light;

    @include rh($mob) {
      font-size: 1.6rem; }

    p {
      &:not(:last-of-type) {
        margin-bottom: 2rem; } } }

  &__tag {
    background-color: transparent;
    color: #C4C4C4;
    display: inline-block;
    font-size: 4.8rem;
    position: relative;
    line-height: 1.2;
    letter-spacing: 0.02em;
    padding-bottom: 0.04em;
    text-decoration: none;
    text-transform: lowercase;
    @include mont-bold;

    span {
      bottom: 0;
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 1px;

      &:last-child {
        left: auto;
        right: 0; } } }

  &__btn,
  &__link {
    background-color: transparent;
    color: #ffffff;
    display: block;
    font-size: 1.8rem;
    letter-spacing: 0.08em;
    opacity: 0.3;
    text-decoration: none;
    transition: opacity 0.3s;
    white-space: nowrap;
    @include mont-req;

    &:hover {
      opacity: 1; } }

  &__link {
    margin-top: 7.3rem;
    top: 50%;
    transform: translateY(-50%);

    @include rh($mob) {
      margin-top: 4rem; } } }
