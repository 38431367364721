.final {
  font-size: 2.4rem;
  line-height: 1.2;
  width: 100%;
  @include mont-req;

  @include rh($mob) {
    font-size: 2rem; }

  &__dot {
    position: absolute;
    top: 50%;

    &:nth-of-type(1) {
      left: 0; }

    &:nth-of-type(2) {
      left: 20vw;
      left: calc(var(--vw, 1) * 30); } }

  &__text {
    padding-left: 36%;
    position: relative;
    top: -0.5em;
    transform: translateY(50%); }

  p {
    &:not(:last-of-type) {
      margin-bottom: 15px; }

    &:nth-of-type(2) {
      color: inherit;
      font-size: 3.2rem;
      line-height: 0.9em;
      text-decoration: none;
      @include light-italic; } } }
