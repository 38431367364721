.son {
  $this: &;
  height: 100%;
  padding: $x;
  position: relative;
  width: 100%;
  @include mont-req;

  &__in {
    height: inherit;
    display: flex;
    justify-content: space-between;
    width: inherit; }

  &__col {
    position: relative;
    min-width: 50%;
    width: 50%;

    &:nth-of-type(2) {
      align-items: center; } }

  &__photo {
    align-items: center;
    display: flex;
    height: 80%;
    left: 5%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 10%;
    justify-content: center;
    visibility: hidden;
    width: 90%;

    img {
      display: block;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      object-fit: cover; } }

  &__item {
    height: 100%;
    font-size: 4rem;
    line-height: 1.2;
    letter-spacing: 0.06em;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:first-of-type {
      color: #ffffff;
      left: 50%;
      transition: color 0.8s;
      width: auto;

      &:before {
        @include pseudo;
        animation: poly-reversed 0.8s;
        background-image: url('./../img/uk/h-son-1.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        height: 140%;
        opacity: 0;
        transform: translateY(-0.4em);
        transition: clip-path 0.8s, visibility 0.8s, opacity 0.8s;
        visibility: hidden;
        width: 7em;

        [lang="en"] & {
          background-image: url('./../img/en/h-son-1.svg');
          transform: translateY(0); } }

      &:hover {
        color: transparent;

        &:before {
          animation: poly 1s;
          opacity: 1;
          visibility: visible; } } }

    img {
      height: 100%;
      display: block;
      left: 50%;
      max-height: 100%;
      max-width: 70%;
      object-fit: contain;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%); }

    &_align-center {
      cursor: pointer;
      height: auto;
      text-align: center;
      top: 50%;
      transform: translateY(-50%); } }

  &__paragraphs {
    &_last {
      min-width: 820px; } }

  &__text {
    font-size: 2.2rem;
    line-height: 1.5;
    left: 14vw;
    max-width: calc(620px + 14vw);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &:first-of-type {
      font-size: 1.9rem;
      line-height: 1.3em;
      letter-spacing: 0.04em;
      @include pt-italic; }

    &_hidden {
      opacity: 0;
      visibility: hidden; } }

  &__title {
    color: #D8D1D1;
    font-size: 4.8rem;
    line-height: 1.2;
    letter-spacing: 0.015em;
    margin-bottom: 6rem;
    @include light-italic; }

  &__dot {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;

    &:nth-child(2) {
      left: 99.8%; } }

  &__item,
  &__text,
  &__photo {
    transition: opacity 0.6s, visibility 0.6s; }

  &.is-first-section-played {
    #{$this} {
      &__item,
      &__text,
      &__photo {
        transition: none; } } }

  &__item,
  &__text #{$this}__paragraphs {
    &:not(:first-child) {
      opacity: 0;
      visibility: hidden; } }

  &__title {
    opacity: 0;
    visibility: hidden; }

  &__in {
    &.is-hovered {

      #{$this}__photo {
        opacity: 1;
        visibility: visible; } } }

  [data-story="second"] & {
    &__item {
      &:first-of-type {
        &:before {
          background-image: url('./../img/uk/h-son-2.svg');
          transform: translateY(-0.5em);

          [lang="en"] & {
            background-image: url('./../img/en/h-son-2.svg');
            transform: translateY(0); } } } } } }
