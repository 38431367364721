.questions {
  $this: &;
  &__item {
    border: 1px solid #D8D1D1;
    cursor: pointer;
    overflow: hidden;
    padding: 2.2rem 7rem;
    position: relative;
    margin: auto;
    max-width: 1382px;
    transition: height ease-out 0.3s;
    width: 95%;

    &:not(:last-of-type) {
      margin-bottom: 3rem; } }

  &__headline {
    position: relative;

    h2#{$this}__title {
      font-size: 2.2rem;
      line-height: 1.5;
      margin: 0;
      @include mont-req; } }

  &__num {
    font-size: 1.8rem;
    line-height: 1;
    padding-right: 2rem;
    position: absolute;
    top: 0.4rem;
    left: 0;
    transform: translateX(-100%);
    @include mont-light; }

  &__text {
    font-size: 1.8rem;
    margin-top: 4.2rem;
    position: absolute;
    padding-bottom: 2.2rem;
    top: 100%;
    left: 0;
    width: 100%;
    @include mont-light; }

  p {
   max-width: none !important; }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 2.2rem;
    margin: 2.2rem 0;

    li {
      display: list-item; } } }
