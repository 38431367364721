.hysterics {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  video {
    height: 100%;
    object-fit: cover;
    width: 100%; }

  &__text {
    bottom: 152px;
    font-size: 2.3rem;
    line-height: 1.2em;
    letter-spacing: 0.02em;
    position: absolute;
    right: 120px;
    @include mont-light; }

  &__path {
    display: block;
    left: -20%;
    opacity: 0.3;
    position: absolute;
    top: calc(50% + 0.85vw);
    transform: translate(0, -50%);
    width: 70%;

    svg {
      display: block;
      stroke-width: 1.5; }

    path {
      display: block;
      stroke-dasharray: 2146;
      stroke-dashoffset: 2146; } } }
