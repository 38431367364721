.video-360 {
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  position: relative;
  width: 100vw;
  width: calc(var(--vw, 1) * 100);

  &__title {
    color: #F8EFEF;
    font-size: 2rem;
    line-height: 1.2;
    left: $x;
    position: absolute;
    top: $y;
    @include mont-req; }

  &__icon {
    height: 90px;
    left: $x;
    overflow: hidden;
    position: absolute;
    top: $y;
    width: 90px;

    svg {
      display: block;
      fill: #F2E7E7;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }

    &_position {
      bottom: $y;
      height: 74px;
      top: auto;
      width: 74px;

      svg {
        &:nth-child(2) {
          top: 0;
          left: 0;
          transform-origin: center; } } } }

  &__btn {
    background-color: transparent;
    color: #ffffff;
    font-size: 1.8rem;
    opacity: 0.6;
    letter-spacing: 0.1em;
    line-height: 1.28;
    position: absolute;
    right: $x;
    top: $y;
    transition: opacity ease-out 0.3s;
    @include mont-light;

    &:hover {
      opacity: 1; } }

  &__progress {
    background-color: rgba(#C4C4C4, 0.5);
    bottom: 0;
    height: 5px;
    position: absolute;
    width: 100%;

    &-value {
      background-color: #F3F3F3;
      height: 100%;
      position: absolute;
      transform: scaleX(0);
      transition: transform ease-out 0.3s;
      transform-origin: top left;
      width: 100%; } }

  &__arrow {
    cursor: pointer;
    font-size: 18.7rem;
    opacity: 1;
    padding: 10rem $x;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity ease-out 0.3s;

    &:hover {
      opacity: 0.5; }

    svg {
      fill: #D8D1D1; }

    &_left {
      left: 0; }

    &_right {
      right: 0;

      svg {
        transform: rotate(180deg); } } }

  .video-js {
    height: 100%;
    left: 0;
    position: absolute;
    top: -1px;
    width: 100%; } }
