.intro {
  $this: &;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%;

  [lang="en"] & {
    &__start {
      #{$this}__col {
        &:first-of-type {
          width: 80%; }

        &:last-of-type {
          width: 20%; } } }

    &__title {
      font-size: 1.7rem;
      left: auto;
      margin-top: 1rem;
      right: -21.5rem;
      top: 50%;
      white-space: nowrap; }

    &__logo {
      left: 0;
      width: 85%; }

    svg {
      path {
        &:nth-child(1) {
          stroke-dasharray: 512; }

        &:nth-child(2) {
          stroke-dasharray: 201; }

        &:nth-child(3) {
          stroke-dasharray: 775; }

        &:nth-child(4) {
          stroke-dasharray: 601; }

        &:nth-child(5) {
          stroke-dasharray: 458; }

        &:nth-child(6) {
          stroke-dasharray: 460; }

        &:nth-child(7) {
          stroke-dasharray: 702; }

        &:nth-child(8) {
          stroke-dasharray: 708; }

        &:nth-child(9) {
          stroke-dasharray: 576; }

        &:nth-child(10) {
          stroke-dasharray: 564; }

        &:nth-child(11) {
          stroke-dasharray: 230; }

        &:nth-child(12) {
          stroke-dasharray: 140; }

        &:nth-child(13) {
          stroke-dasharray: 162; }

        &:nth-child(14) {
          stroke-dasharray: 257; } } } }

  &__in {
    display: flex;
    height: 100%;
    opacity: 0;
    padding: $y 0;
    transition: transform ease-out 1s;
    visibility: hidden; }

  .is-forced & {
    &__in {
      transition: none; } }

  &__row {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    justify-content: space-between; }

  &__row,
  &__choose {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100vw;
    width: calc(var(--vw, 1) * 100); }

  &__col {
    flex-grow: 1;
    flex-shrink: 1;
    width: 50%;

    & > #{$this}__row {
      width: 100%; } }

  &__start {
    #{$this} {
      &__col {
        &:first-of-type {
          width: 65%; }

        &:last-of-type {
          left: -6%;
          position: relative;
          width: 50%; } }

      &__text {
        left: auto;
        right: 0;
        text-align: right;

        &:before {
          background-color: #ffffff;
          content: '';
          height: 2px;
          position: absolute;
          right: -5.9vw;
          opacity: 0.22;
          top: 50%;
          transition: width 0.7s 0.3s, opacity 0.6s;
          transform-origin: top left;
          width: 5vw; } } } }

  &__logo {
    left: -5%;
    position: relative;
    transform: translateY(-5%);

    svg {
      display: block;
      opacity: 0.8;
      width: 100%;

      path {
        stroke-dashoffset: 100;

        &:nth-child(1) {
          stroke-dasharray: 1550; }

        &:nth-child(2) {
          stroke-dasharray: 362; }

        &:nth-child(3) {
          stroke-dasharray: 986; }

        &:nth-child(4) {
          stroke-dasharray: 319; }

        &:nth-child(5) {
          stroke-dasharray: 1227; }

        &:nth-child(6) {
          stroke-dasharray: 981; }

        &:nth-child(7) {
          stroke-dasharray: 336; }

        &:nth-child(8) {
          stroke-dasharray: 266; }

        &:nth-child(9) {
          stroke-dasharray: 1955; } } }

    &.is-animating {
      path {
        animation: draw-logo 3s forwards; } } }

  &__title {
    color: #E1DCD6;
    font-size: 4rem;
    letter-spacing: 0.09em;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-20%, -20%);
    @include mont-bold; }

  &__btn {
    background-color: transparent;
    color: #ffffff;
    font-size: 1.8rem;
    letter-spacing: 0.1em;
    position: fixed;
    top: $y;
    z-index: 1; }

  &__btn,
  &__text {
    right: $x; }

  &__text {
    display: block;
    font-size: 1.9rem;
    left: 20vw;
    position: relative;
    text-align: left;
    @include pt-italic;

    &_call-btn {
      cursor: pointer;

      &:before {
        animation: line 2s infinite forwards ease; } }

    &.is-active {
      &:before {
        padding-left: 0.8vw;
        width: 5vw; } }

    .is-first-screen-played & {
      &:before {
        animation: none;
        opacity: 0;
        width: 50%; } } }

  &__choose {
    text-align: center;

    #{$this}__row {
      height: 100%; }

    #{$this}__text {
      opacity: 0;
      transition: opacity 0.6s, visibility 0.6s;
      visibility: hidden;

      &:nth-child(1) {
        opacity: 1;
        visibility: visible; }

      .is-promo-visible & {
        opacity: 1;
        visibility: visible; } } }

  &__links {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 16vw;
    position: relative;
    top: -2rem;
    transform: translateY(50%); }

  &__link {
    color: #D8D1D1;
    font-size: 4rem;
    display: inline-block;
    letter-spacing: 0.06em;
    opacity: 1;
    position: relative;
    text-align: left;
    text-decoration: none;
    visibility: visible;

    &:not(:last-of-type) {
      margin-bottom: 9.7rem; }

    &-small {
      position: relative;
      font-size: 1.9rem;
      line-height: 81.69%;
      white-space: nowrap;
      letter-spacing: 0.05em;
      opacity: 0.8;
      transition: opacity .3s ease;
      border-bottom: 1px solid transparent;
      @include pt-italic;

      &:hover {
        color: #fff;
        opacity: 1; } } }

  &__photo {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80%;
    left: 5%;
    opacity: 0;
    position: fixed;
    top: 10%;
    transition: opacity 0.8s, visibility 0.8s;
    visibility: hidden;
    width: 90%;

    .is-promo-visible & {
      opacity: 1;
      visibility: visible; }

    [data-history="first"] & {
      background-image: url('/img/mom-1.png'); }

    [data-history="second"] & {
      background-image: url('/img/mom-2.png'); } }

  .is-first-screen-played & {
    &__in {
      transform: translateX(-100%); } } }

.intro-section-name {
  $this: &;
  position: relative;

  [lang="en"] & {
    &__img {
      bottom: 0; } }

  &__txt {
    font-size: 4rem;
    line-height: 1.2em;
    letter-spacing: 0.06em;
    width: 32.4rem;
    @include mont-req; }

  &__img {
    animation: poly-reversed 1s;
    bottom: -0.4em;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: clip-path 0.8s;
    visibility: hidden;
    width: 100%;

    img {
      display: block;
      width: 100%; } }

  &__txt {
    opacity: 1;
    visibility: visible; }

  &__txt,
  &__img {
    transition: visibility 0.8s, opacity 0.8s; }

  &:hover {
    #{$this} {
      &__img {
        animation: poly 1s;
        opacity: 1;
        visibility: visible; }

      &__txt {
        opacity: 0;
        visibility: hidden; } } } }

@keyframes poly {
  0% {
    clip-path: polygon(0 0, 0 0, 0 0, 0% 100%);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0% 100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

@keyframes poly-reversed {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  100% {
    clip-path: polygon(0 0, 100% 0, 0 0, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 0 0, 0% 100%); } }

@keyframes line {
  0% {
    transform: scaleX(0);
    transform-origin: top left; }
  50% {
    transform: scaleX(1);
    transform-origin: top left; }
  51% {
    transform: scaleX(1);
    transform-origin: top right; }
  100% {
    transform: scaleX(0);
    transform-origin: top right; } }

@keyframes draw-logo {
  0% {
    stroke-dashoffset: 100; }
  80%, 100% {
    stroke-dashoffset: 0; } }

.is-modal-visible {
	.intro {
		opacity: 0 !important;
		visibility: hidden !important; } }
