.preloader {
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  justify-content: center;
  transition: visibility 1s, opacity 1s;
  width: 100%;

  &__numbers {
    svg {
      display: none;
      opacity: 0;
      visibility: hidden; } }

  &__text {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: visibility 0.6s 1s, opacity 0.6s 1s;

    .is-ie & {
      opacity: 1;
      visibility: visible; } }

  &__item {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-flex;
    height: 25vh;
    height: calc(var(--vh, 1) * 25);
    max-height: 196px;
    margin: auto;
    opacity: 0.4;
    position: relative;
    transition: opacity 1s;

    &.is-hidden {
      opacity: 0; }

    svg {
      display: block;
      height: 100%; }

    path {
      stroke-dasharray: 1400;
      animation: draw 2.5s; }

    &[data-number="100"] {
      svg {
        position: relative;

        &:first-of-type {
          left: 3vh; }

        &:last-of-type {
          right: 1vh; } } } } }

@keyframes draw {
  0% {
    stroke-dashoffset: 2750; }
  80%, 100% {
    stroke-dashoffset: 2800; } }
