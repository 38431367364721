.sound-on {
  $this: &;
  bottom: $y;
  cursor: pointer;
  left: 50%;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;

  &:hover {
    #{$this}__title {
      opacity: 1; } }

  svg {
    fill: #fff;
    width: 2.1rem; }

  &__title {
    color: #D8D1D1;
    display: block;
    font-size: 1.9rem;
    margin-top: 1rem;
    letter-spacing: 0.05em;
    line-height: 1.3;
    opacity: 0.8;
    transition: opacity ease-in-out 0.3s;
    @include pt-italic; } }
