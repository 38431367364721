.whats-next {
  $this: &;
  height: 100vh;
  height: calc(var(--vh, 1) * 100);
  left: 0;
  overflow: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: transform linear 0.6s;
  transform: translateX(100vw);
  visibility: hidden;
  width: 100vw;
  width: calc(var(--vw, 1) * 100);
  z-index: 1000;

  [lang="en"] & {
    &__title {
      font-size: 3.2rem; } }

  &__in {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $x $x;
    // transform: translateY(38%)
    // transition: transform ease-out 1s

    // .is-scrolled &
 }    //   transform: translateY(0%)

  &__row {
    padding: 30px 0;
    position: relative;

    &_top {
      align-items: flex-end;
      display: flex;
      padding-right: 10rem;
      padding-left: 7rem;
      justify-content: space-between;

      &:before {
        @include pseudo;
        background-color: rgba(255, 255, 255, 0.5);
        height: 1px;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -50%);
        width: 100vw; } }

    &_bottom {
      height: 100%;
      margin-top: 30px;
      overflow: hidden;
      padding: 0; } }

  &__item {
    cursor: pointer;
    position: relative;

    #{$this}__index,
    #{$this}__title {
      transition: opacity 0.3s; }

    &:hover {
      #{$this}__index,
      #{$this}__title {
        opacity: 0.8; } }

    &.is-active {
      #{$this}__index,
      #{$this}__title {
        opacity: 1; } } }

  &__title {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.055em;
    opacity: 0.5;
    @include mont-req; }

  &__index {
    bottom: 0.8em;
    color: #ffffff;
    font-size: 1.8rem;
    left: 0;
    line-height: 0;
    opacity: 0.3;
    padding-bottom: 0.15rem;
    position: absolute;
    transform: translate(-100%, -50%);
    white-space: nowrap;
    width: 7rem;
    @include mont-light; }

  &__socials {
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateX(100%);
    right: -8rem;
    top: 0; }

  &__textual {
    color: #C4C4C4;
    height: 100%;
    font-size: 2.2rem;
    line-height: 1.5;
    opacity: 0;
    overflow: hidden;
    padding-top: 3vh;
    padding-left: 5vw;
    position: absolute;
    visibility: hidden;
    width: 100%;
    @include mont-req;

    &_manifest {
      padding-left: 0; }

    &.is-active {
      opacity: 1;
      visibility: visible;
      z-index: 1; }

    h2 {
      font-size: 2.4rem;
      line-height: 1.6;
      margin: 8rem 0 4rem;
      @include mont-bold; }

    a {
      color: inherit;
      opacity: 1;
      transition: opacity ease 0.3s;

      & > span {
       color: inherit !important; }

      &:hover {
        opacity: 0.8; } }

    p + ul {
      margin-top: 50px; }

    ul {
      li {
        &:not(:last-of-type) {
          margin-bottom: 67px; } } }

    li {
      display: block;
      position: relative;
      width: 62%;

      & > span {
        font-size: 1.8rem;
        position: absolute;
        top: 0;
        left: -5rem;
        line-height: 1.8;
        @include mont-light; } }

    svg {
      fill: transparent;
      stroke: #ffffff; }

    &:nth-of-type(1) {
      p {
        max-width: 64.5%; } }

    &:nth-of-type(3) {
      p {
        max-width: 56%; } }

    p {
      &:first-child {
        max-width: 64.5%; }

      &:not(:last-of-type) {
        margin-bottom: 4rem; } } }

  &__item,
  &__textual {
    transition: opacity 0.6s, visibility 0.6s; }

  &__btn {
    background-color: transparent;
    color: #DBD5D5;
    letter-spacing: 0.1em;
    right: $x;
    position: fixed;
    top: 30px;
    transform: translateY(50%);
    @include mont-light;

    & > svg {
      font-size: 2rem;
      stroke: #ffffff;
      opacity: 0.8;
      transition: opacity ease-out 0.3s; }

    &:hover {
      & > svg {
        opacity: 1; } } }

  &__social {
    font-size: 30px;

    &:not(:last-of-type) {
      margin-bottom: 1.5rem; } }

  &__partners {
    align-items: center;
    display: flex;
    padding-top: 12rem; }

  &__partner {
    display: block;
    max-width: 188px;

    img {
      display: block;
      max-height: 7.4rem;
      max-width: 100%; }

    &:not(:last-child) {
      margin-right: 8.7rem; }

    &:last-child {
      img {
        max-height: 5rem; } } }

  .ps__rail-y.ps--clicking .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y {
    width: 6px; }

  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y.ps--clicking,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-y:hover {
    background-color: transparent; } }

